<template>
  <card-list>
    <div class="row mt-4">
      <div class="col-sm-12 col-md-2">
        <label class="d-inline-flex align-items-center">
          <span class="d-none d-sm-inline">Mostrar</span>
          <b-form-select
            class="ml-2 mr-1"
            v-model="paginacao.itensPorPagina"
            size="sm"
            :options="$tableItens"
            @input="getSearch"
          ></b-form-select>
        </label>
      </div>
      <div class="col-sm-12 col-md-3">
        <label class="d-inline-flex align-items-center">
          Hospital:
          <b-form-input
            v-model="filter.hospital"
            v-debounce:450="getSearch"
            type="search"
            class="ml-2 form-control form-control-sm ms-2 "
          ></b-form-input>
        </label>
      </div>
      <div class="col-sm-12 col-md-2">
        <label class="d-inline-flex align-items-center">
          Lote:
          <b-form-input
            v-model="filter.lote"
            v-debounce:450="getSearch"
            type="number"
            class="ml-2 form-control form-control-sm ms-2 "
          ></b-form-input>
        </label>
      </div>
      <div class="col-sm-12 col-md-4">
        <div class="d-inline-flex align-items-center">
          <label class=" d-inline-flex align-items-center">
            Período:&nbsp;&nbsp;
            <date-picker
              v-model="filter.periodoStart"
              :first-day-of-week="1"
              lang="pt-BR"
              format="DD/MM/YYYY"
              @change="getSearch()"
            ></date-picker>
          </label>
          <span class="mx-1 pl-1 pr-1"> a </span>
          <label class="d-inline-flex align-items-center fw-normal">
            <date-picker
              v-model="filter.periodoEnd"
              :first-day-of-week="1"
              lang="pt-BR"
              format="DD/MM/YYYY"
              @change="getSearch()"
              class="ml-2 "
            ></date-picker>
          </label>
        </div>
      </div>
    </div>
    <div class="row mb-2">
      <div class="col-sm-12 col-md-6">
        <label class="d-inline-flex align-items-center">
          <span class="d-block">Status:</span>
          <div v-for="status in statusList" :key="status.value" class="ms-2">
            <input
              type="radio"
              class="form-check-input me-1"
              :id="`status-${status.value}`"
              v-model="filter.status"
              :value="status.value"
              @change="getSearch()"
            />
            <label class="form-check-label" :for="`status-${status.value}`">
              {{ status.label }}
            </label>
          </div>
        </label>
      </div>
      <div class="col-sm-12 col-md-6">
        <label class="d-inline-flex align-items-center">
          <span class="d-block">Lotes:</span>
          <div v-for="atribuicao in atribuicaoList" :key="atribuicao.value" class="ms-2">
            <input
              type="radio"
              class="form-check-input me-1"
              :id="`atribuicao-${atribuicao.value}`"
              v-model="filter.apenasMeusLotes"
              :value="atribuicao.value"
              @change="getSearch()"
            />
            <label class="form-check-label" :for="`atribuicao-${atribuicao.value}`">
              {{ atribuicao.label }}
            </label>
          </div>
        </label>
      </div>
    </div>

    <div class="table-responsive">
      <b-table 
          striped hover small 
          :items="items" 
          :busy="loading"
          :fields="colunas">

        <template #table-busy>
          <ui-loading></ui-loading>
        </template>

        <template #cell(valorTotal)="data">
          {{ data.item.valorTotal | toCurrency }}
        </template>
        <template #cell(valorGlosa)="data">
          {{ data.item.valorGlosa | toCurrency }}
        </template>
        <template #cell(status)="data">
          {{ translateStatusLote(data.item.status) }}
        </template>

        <template #cell(action)="data">
          <b-button-group size="sm">
            <b-button
              variant="outline-primary"
              title="ver"
              @click="$router.push({name: 'faturamento.lotes.detalhe', params: {id: data.item.id}})"
            >
              <i class="uil uil-eye icon-size"></i>
            </b-button>

            <b-button v-if="data.item.status != 'AUDITADO'"
              variant="outline-primary"
              title="auditar"
              v-authorize="'lotesScLotesAuditar'"
              @click="$router.push({name: 'faturamento.lotes.auditar', params: {id: data.item.id}})"
            >
              <i class="uil uil-pen icon-size"></i>
            </b-button>

            <b-button v-if="data.item.status == 'AUDITADO' && isUserAdm"
              variant="outline-primary"
              title="reabrir auditoria"
              v-authorize="'lotesScLotesAuditar'"
              @click="reabrirAuditoria(data.item.id)"
            >
              <i class="uil uil-sync-exclamation icon-size"></i>
            </b-button>
          </b-button-group>
        </template>

      </b-table>
    </div>
    <div class="row">
      <div class="col">
        <div class="dataTables_paginate paging_simple_numbers float-end">
          <ul class="pagination pagination-rounded">
            <!-- pagination -->
            <b-pagination
              v-model="paginacao.pagina"
              :total-rows="paginacao.TotalPaginas"
              :per-page="1"
              @input="getResults"
            ></b-pagination>
          </ul>
        </div>
      </div>
    </div>
  </card-list>

</template>

<script>
import CardList from "@/components/Ui/CardList";

import { getAllLotes, reabrirAuditoriaLote } from "../../../services/faturamento.service";
import { translateStatusLote, statusLoteRadios } from '../../../utils/mixins.statuses.js'
import { messageConfirm, messageSuccess } from "../../../utils/messages";
import { trataErro } from "../../../utils/tratar.retornos";
import { ATRIBUICAO } from "../../../services/atribuicao.service";

export default {
  components: {
    CardList
  },
  data() {
    return {
      isUserAdm: false,
      translateStatusLote,
      filter: {
        hospital: '',
        lote: '',
        periodoStart: '',
        periodoEnd: '',
        status: 'TODOS',
        apenasMeusLotes: false
      },
      colunas: [
        { key: "lote", label: "Lote" },
        { key: "hospital", label: "Hospital" },
        { key: "convenio", label: "Convênio" },
        
        { key: "valorTotal", label: "Valor Total" },
        { key: "valorGlosa", label: "Glosa" },
        { key: "qtdGuias", label: "Qtd. Guias" },
        { key: "auditadoPor", label: "Auditado Por" },
        { key: "status", label: "Status" },
        { key: "action", label: "Ações", thStyle: 'width: 80px;', thClass: 'text-center', tdClass: 'text-center' },
      ],
      periodo: {},
      items: [],
      paginacao: {
        pagina: 1,
        itensPorPagina: this.$tableItensDefault,
        TotalPaginas: 1,
        totalRegistros: 1,
      },
      ordenacao: {},
      loading: true,
      statusList: [],
      atribuicaoList: [
        { value: false, label: 'Todos' },
        { value: true, label: 'Atribuídos a mim' }
      ]
    };
  },
  mounted() {
    let user = JSON.parse(localStorage.getItem("user"));
    this.isUserAdm = user.atribuicaoId == ATRIBUICAO.ADMINISTRATIVO;

    this.statusList = statusLoteRadios(true);
    this.getSearch();
  },
  methods: {
    getParam() {
      return Object.assign(
        this.filter,
        {
          paginacao: this.paginacao,
        }
      );
    },
    getSearch() {
      this.paginacao.pagina = 1;
      this.getResults();
    },
    getResults() {
      this.loading = true
      getAllLotes(this.getParam()).then(({ data }) => {
        this.items = data.resultado;
        this.paginacao.pagina = data.paginacao.pagina;
        this.paginacao.TotalPaginas = data.paginacao.totalPaginas;
        this.paginacao.itensPorPagina = data.paginacao.itensPorPagina;
        this.paginacao.totalRegistros = data.paginacao.totalRegistros;
        this.loading = false
      }).catch(err => {
        console.error(err)
      });
    },
    reabrirAuditoria(loteId) {
      messageConfirm(this, "O lote voltará para o status \"Em auditoria\". Deseja continuar?", "Deseja reabrir a auditoria?")
        .then((result) => {
          if (result.isConfirmed) {
            reabrirAuditoriaLote(loteId).then(() => {
              messageSuccess(this, 'Auditoria do lote reaberta')
              this.$router.push({
                name: 'faturamento.lotes.auditar',
                params: {
                  id: loteId
                },
              })
            }).catch((err) => {
              trataErro(err, this)
            })
          }
        })
    },
  },
};
</script>

